import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { SimulationContext } from "../provider/SimulationProvider";
// utils
import { Colors } from "../../utils/Colors";
// images
import item01 from "../../images/detail_item_01.png";
import item02 from "../../images/detail_item_02.png";
import item03 from "../../images/detail_item_03.png";
import item04 from "../../images/detail_item_04.png";

export const DetailItemSelect = () => {
  const { isOpen } = useContext(DetailToggleContext);
  const { sims, setSims } = useContext(SimulationContext);
  const updateSimList = (e) => {
    const target = e.currentTarget.getAttribute("data-item");
    setSims({ item: target, color: sims.color, pattern: sims.pattern });
  };
  const detailBtns = [
    {
      name: "item01",
      model: "item01-xxxxx",
      tag: "Tシャツ",
      price: "3,000",
      image: item01,
    },
    {
      name: "item02",
      model: "item02-xxxxx",
      tag: "Vネック",
      price: "3,000",
      image: item02,
    },
    {
      name: "item03",
      model: "item03-xxxxx",
      tag: "ポロシャツ",
      price: "3,500",
      image: item03,
    },
    {
      name: "item04",
      model: "item04-xxxxx",
      tag: "Yシャツ",
      price: "4,000",
      image: item04,
    },
  ];
  return (
    <SDetail data-detail-state={isOpen}>
      {detailBtns.map(({ name, model, tag, price, image }) => {
        return (
          <SBtn key={name} data-item={name} onClick={updateSimList}>
            <img src={image} />
            <div>
              <span>{model}</span>
              <span>{tag}</span>
              <span>¥ {price}</span>
            </div>
          </SBtn>
        );
      })}
    </SDetail>
  );
};

const SDetail = styled.nav`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  transition: all 0.2s;
  transform-origin: left;
  background-color: ${Colors.white};
  padding: 0 2rem;
  height: 0;
  transition: all 0.2s;
  transform-origin: left;
  &[data-detail-state="item"] {
    height: 185px;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    &[data-detail-state="item"] {
      height: calc(120px + 2rem);
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${Colors.gray};
  border-radius: 16px;
  height: 120px;
  margin-right: 8px;
  flex: 0 0 auto;
  border: 2px solid ${Colors.gray};
  display: flex;
  align-items: center;
  padding: 1rem;
  &:last-of-type {
    margin-right: 0;
  }
  img {
    width: 100px;
    height: 100px;
  }
  div {
    margin-left: 1rem;
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    span {
      line-height: 1;
      font-size: 0.8rem;
      &:nth-of-type(1) {
        font-weight: 700;
      }
      &:nth-of-type(2) {
        margin-top: 1.5rem;
      }
      &:nth-of-type(3) {
        margin-top: 0.5rem;
      }
    }
  }
`;
