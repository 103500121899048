import { createContext, useState } from "react";

export const MenuToggleContext = createContext({});

export const MenuToggleProvider = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MenuToggleContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MenuToggleContext.Provider>
  );
};
