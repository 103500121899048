import styled from "@emotion/styled";
// components
import { Hamburger } from "../btn/Hamburger";
// utils
import { Colors } from "../../utils/Colors";

export const Header = () => {
  return (
    <SHeader>
      <SLogo>SODA</SLogo>
      <Hamburger />
    </SHeader>
  );
};

const SHeader = styled.header`
  grid-area: header;
  height: 64px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.2);
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SLogo = styled.h2`
  font-weight: 700;
  color: ${Colors.primary};
`;
