import { createContext, useState } from "react";

export const DetailToggleContext = createContext({});

export const DetailToggleProvider = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState("close");

  return (
    <DetailToggleContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </DetailToggleContext.Provider>
  );
};
