import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { SimulationContext } from "../provider/SimulationProvider";
// utils
import { Colors } from "../../utils/Colors";

export const DetailPatternSelect = () => {
  const { isOpen } = useContext(DetailToggleContext);
  const { sims, setSims } = useContext(SimulationContext);
  const detailBtns = [{ name: "pattern01" }, { name: "pattern02" }];
  const updateSimList = (e) => {
    const target = e.currentTarget.getAttribute("data-pattern");
    setSims({ item: sims.item, color: sims.color, pattern: target });
    alert("準備中です。");
  };
  return (
    <SDetail data-detail-state={isOpen}>
      {detailBtns.map(({ name }) => {
        return (
          <SBtn key={name} data-pattern={name} onClick={updateSimList}>
            {name}
          </SBtn>
        );
      })}
    </SDetail>
  );
};

const SDetail = styled.nav`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  transition: all 0.2s;
  transform-origin: left;
  background-color: ${Colors.white};
  padding: 0 2rem;
  height: 0;
  transition: all 0.2s;
  transform-origin: left;
  &[data-detail-state="pattern"] {
    height: 185px;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    &[data-detail-state="pattern"] {
      height: calc(120px + 2rem);
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${Colors.gray};
  border-radius: 16px;
  width: 220px;
  height: 120px;
  margin-right: 8px;
  flex: 0 0 auto;
  &:last-of-type {
    margin-right: 0;
  }
`;
