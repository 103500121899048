import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { DetailItemSelect } from "./DetailItemSelect";
import { DetailColorSelect } from "./DetailColorSelect";
import { DetailPatternSelect } from "./DetailPatternSelect";
import { DetailSaveSelect } from "./DetailSaveSelect";
// utils
import { Colors } from "../../utils/Colors";

export const Detail = () => {
  const { isOpen } = useContext(DetailToggleContext);
  return (
    <SDetail data-detail-state={isOpen}>
      <DetailItemSelect />
      <DetailColorSelect />
      <DetailPatternSelect />
      <DetailSaveSelect />
    </SDetail>
  );
};

const SDetail = styled.div`
  grid-area: detail;
  width: 100%;
  height: auto;
  overflow: hidden;
  border-top: 1px solid ${Colors.gray};
`;
