import styled from "@emotion/styled";
// utils
import { Colors } from "../../utils/Colors";

export const Control = () => {
  return (
    <SControl>
      <div>
        <button className="material-icons-outlined">zoom_in</button>
        <button className="material-icons-outlined">zoom_out</button>
        <button className="material-icons-outlined">swipe_left</button>
        <button className="material-icons-outlined">compare_arrows</button>
      </div>
    </SControl>
  );
};

const SControl = styled.div`
  grid-area: control;
  display: flex;
  align-items: center;
  width: 64px;
  div {
    box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 0 16px 16px 0;
    height: auto;
    background-color: ${Colors.white};
    button {
      width: 32px;
      height: 32px;
      border: none;
      background: none;
      margin-bottom: 0.5rem;
      cursor: pointer;
      border-radius: 100vh;
      transition: all 0.2s;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        background-color: ${Colors.gray};
      }
    }
  }
`;
