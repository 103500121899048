import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { SimulationContext } from "../provider/SimulationProvider";
// utils
import { Colors } from "../../utils/Colors";

export const DetailColorSelect = () => {
  const { isOpen } = useContext(DetailToggleContext);
  const { sims, setSims } = useContext(SimulationContext);
  const updateSimList = (e) => {
    const target = e.currentTarget.getAttribute("data-color");
    setSims({ item: sims.item, color: target, pattern: sims.pattern });
  };
  const detailBtns = [
    { name: "color01", tag: "ホワイト", code: "#FFFFFF" },
    { name: "color02", tag: "ピンク", code: "#FFCCCC" },
    { name: "color03", tag: "レッド", code: "#FF4D4D" },
    { name: "color04", tag: "オレンジ", code: "#FFAF40" },
    { name: "color05", tag: "イエロー", code: "#FFFA65" },
    { name: "color06", tag: "グリーン", code: "#32FF7E" },
    { name: "color07", tag: "ブルー", code: "#18DCFF" },
    { name: "color08", tag: "ブラック", code: "#4B4B4B" },
  ];
  return (
    <SDetail data-detail-state={isOpen}>
      {detailBtns.map(({ name, tag, code }) => {
        return (
          <SBtn key={name} data-color={name} onClick={updateSimList}>
            <span>{tag}</span>
            <span>{code}</span>
          </SBtn>
        );
      })}
    </SDetail>
  );
};

const SDetail = styled.nav`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  transition: all 0.2s;
  transform-origin: left;
  background-color: ${Colors.white};
  padding: 0 2rem;
  height: 0;
  transition: all 0.2s;
  transform-origin: left;
  &[data-detail-state="color"] {
    height: 185px;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    &[data-detail-state="color"] {
      height: calc(120px + 2rem);
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${Colors.gray};
  border-radius: 16px;
  height: 120px;
  margin-right: 8px;
  flex: 0 0 auto;
  padding: 0 2rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:last-of-type {
    margin-right: 0;
  }
  span {
    line-height: 1;
    font-size: 0.8rem;
    &:nth-of-type(1) {
      font-weight: 700;
    }
    &:nth-of-type(2) {
      margin-top: 1rem;
    }
  }
  &[data-color="color01"] {
    background-color: #ffffff;
    border: 1px solid ${Colors.dark};
  }
  &[data-color="color02"] {
    background-color: #ffcccc;
  }
  &[data-color="color03"] {
    background-color: #ff4d4d;
  }
  &[data-color="color04"] {
    background-color: #ffaf40;
  }
  &[data-color="color05"] {
    background-color: #fffa65;
  }
  &[data-color="color06"] {
    background-color: #32ff7e;
  }
  &[data-color="color07"] {
    background-color: #18dcff;
  }
  &[data-color="color08"] {
    background-color: #4b4b4b;
    span {
      color: ${Colors.light};
    }
  }
`;
