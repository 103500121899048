import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { Hamburger } from "../btn/Hamburger";
import { MenuToggleContext } from "../provider/MenuToggleProvider";
// utils
import { Colors } from "../../utils/Colors";

export const Menu = () => {
  const MenuItems = [
    { item: "menu01" },
    { item: "menu02" },
    { item: "menu03" },
    { item: "menu04" },
  ];
  const { isOpen, setIsOpen } = useContext(MenuToggleContext);
  const onClickMenu = () => setIsOpen(false);
  return (
    <SMenu data-menu-state={isOpen}>
      <SMenuHeading>
        <Hamburger />
      </SMenuHeading>
      <SMenuNav>
        {MenuItems.map(({ item }) => {
          return (
            <span key={item} onClick={onClickMenu}>
              {item}
            </span>
          );
        })}
      </SMenuNav>
      <SFooting>
        <p>このサイトはデモサイトです</p>
        <p>Demand Link Co.,Ltd.</p>
      </SFooting>
    </SMenu>
  );
};

const SMenu = styled.div`
  position: fixed;
  width: 320px;
  height: 100vh;
  background-color: ${Colors.primary};
  border-radius: 16px 0 0 16px;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &[data-menu-state="false"] {
    top: 0;
    right: -320px;
  }
  &[data-menu-state="true"] {
    top: 0;
    right: 0;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 90%;
    &[data-menu-state="false"] {
      top: 0;
      right: -90%;
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SMenuHeading = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 2rem;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SMenuNav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: right;
  span {
    color: ${Colors.light};
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 1rem;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SFooting = styled.footer`
  margin-top: auto;
  padding: 2rem;
  text-align: right;
  p {
    color: ${Colors.light};
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    &:nth-of-type(2) {
      margin-top: 0.5rem;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 1rem;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;
