import styled from "@emotion/styled";
import { Control } from "../control/Control";
import { Simulation } from "../simulation/Simulation";

export const Main = () => {
  return (
    <SMain>
      <Control />
      <Simulation />
      <div className="dammy"></div>
    </SMain>
  );
};

const SMain = styled.main`
  grid-area: main;
  display: grid;
  grid-template-areas: "control simulation dammy";
  grid-template-columns: auto 1fr auto;
  .dammy {
    grid-area: dammy;
    width: 64px;
  }
`;
