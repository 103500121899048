import "./styles/global.css";
// components
import { MenuToggleProvider } from "./components/provider/MenuToggleProvider";
import { DetailToggleProvider } from "./components/provider/DetailToggleProvider";
import { SimulationProvider } from "./components/provider/SimulationProvider";
import { Header } from "./components/header/Header";
import { Main } from "./components/main/Main";
import { Nav } from "./components/nav/Nav";
import { Detail } from "./components/detail/Detail";
import { Menu } from "./components/menu/Menu";

const App = () => {
  const vh = window.innerHeight;
  console.log(vh);
  document.getElementById("root").style.height = vh + "px";
  return (
    <>
      <MenuToggleProvider>
        <Header />
        <Menu />
        <SimulationProvider>
          <Main />
          <DetailToggleProvider>
            <Nav />
            <Detail />
          </DetailToggleProvider>
        </SimulationProvider>
      </MenuToggleProvider>
    </>
  );
};

export default App;
