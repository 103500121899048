import { createContext, useState } from "react";

export const SimulationContext = createContext({});

const simList = { item: "item01", color: "color01", pattern: "pattern01" };
export const SimulationProvider = (props) => {
  const { children } = props;
  const [sims, setSims] = useState(simList);

  return (
    <SimulationContext.Provider value={{ sims, setSims }}>
      {children}
    </SimulationContext.Provider>
  );
};
