import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { SimulationContext } from "../provider/SimulationProvider";
// utils
import { Colors } from "../../utils/Colors";

export const DetailSaveSelect = () => {
  const { isOpen } = useContext(DetailToggleContext);
  const { setSims } = useContext(SimulationContext);
  const createDoc = () => {
    alert("このサイトはデモサイトです。");
  };
  const resetSim = () => {
    setSims({ item: "item01", color: "color01", pattern: "pattern01" });
  };
  return (
    <SDetail data-detail-state={isOpen}>
      <SBtn onClick={createDoc}>見積書の作成に進む</SBtn>
      <SBtn onClick={resetSim}>初期状態に戻す</SBtn>
    </SDetail>
  );
};

const SDetail = styled.nav`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  transition: all 0.2s;
  transform-origin: left;
  background-color: ${Colors.white};
  padding: 0 2rem;
  height: 0;
  transition: all 0.2s;
  transform-origin: left;
  &[data-detail-state="save"] {
    height: 185px;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 0 1rem;
    &[data-detail-state="save"] {
      height: calc(120px + 2rem);
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${Colors.gray};
  border-radius: 16px;
  height: 120px;
  margin-right: 8px;
  flex: 0 0 auto;
  padding: 0 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  &:last-of-type {
    margin-right: 0;
  }
  &:nth-of-type(1) {
    background-color: ${Colors.primary};
    color: ${Colors.light};
  }
  &:nth-of-type(2) {
  }
`;
