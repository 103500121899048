import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
// utils
import { Colors } from "../../utils/Colors";
// images
import Icon from "../../images/icon_save.png";

export const NavSaveSelect = () => {
  const { isOpen, setIsOpen } = useContext(DetailToggleContext);
  const onClickDetail = () => setIsOpen("save");
  return (
    <SBtn onClick={onClickDetail} data-detail-state={isOpen}>
      <img src={Icon} alt="icon" />
      <span>見積書作成</span>
    </SBtn>
  );
};

const SBtn = styled.button`
  aspect-ratio: 3/1;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  border: 2px solid ${Colors.gray};
  background-color: ${Colors.gray};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 64px;
  }
  span {
    font-size: 0.9rem;
    line-height: 1;
    font-weight: 700;
    margin-left: 0.5rem;
  }
  &[data-detail-state="save"] {
    border: 2px solid ${Colors.primary};
    img {
      filter: invert(26%) sepia(97%) saturate(5980%) hue-rotate(197deg)
        brightness(95%) contrast(97%);
    }
    span {
      color: ${Colors.primary};
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    aspect-ratio: 1/1;
    flex-direction: column;
    img {
      width: 40px;
    }
    span {
      white-space: nowrap;
      font-size: 1rem;
      transform: scale(0.6);
      margin-left: 0;
      margin-top: 4px;
    }
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;
