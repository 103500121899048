import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { SimulationContext } from "../provider/SimulationProvider";
// images
import item01line from "../../images/item_01_line.png";
import item01bg from "../../images/item_01_bg.png";
import item02line from "../../images/item_02_line.png";
import item02bg from "../../images/item_02_bg.png";
import item03line from "../../images/item_03_line.png";
import item03bg from "../../images/item_03_bg.png";
import item04line from "../../images/item_04_line.png";
import item04bg from "../../images/item_04_bg.png";

export const Simulation = () => {
  const { sims } = useContext(SimulationContext);

  return (
    <SSimulation>
      <div
        data-sim-item={sims.item}
        data-sim-color={sims.color}
        data-sim-pattern={sims.pattern}
      >
        <SImg>
          {sims.item === "item01" && (
            <>
              <img
                src={item01bg}
                data-sim-item={sims.item}
                data-sim-color={sims.color}
                data-sims-pattern={sims.pattern}
                alt="item"
              />
              <img src={item01line} data-sim-item={sims.item} alt="item" />
            </>
          )}
          {sims.item === "item02" && (
            <>
              <img
                src={item02bg}
                data-sim-item={sims.item}
                data-sim-color={sims.color}
                data-sims-pattern={sims.pattern}
                alt="item"
              />
              <img src={item02line} data-sim-item={sims.item} alt="item" />
            </>
          )}
          {sims.item === "item03" && (
            <>
              <img
                src={item03bg}
                data-sim-item={sims.item}
                data-sim-color={sims.color}
                data-sims-pattern={sims.pattern}
                alt="item"
              />
              <img src={item03line} data-sim-item={sims.item} alt="item" />
            </>
          )}
          {sims.item === "item04" && (
            <>
              <img
                src={item04bg}
                data-sim-item={sims.item}
                data-sim-color={sims.color}
                data-sims-pattern={sims.pattern}
                alt="item"
              />
              <img src={item04line} data-sim-item={sims.item} alt="item" />
            </>
          )}
        </SImg>
      </div>
    </SSimulation>
  );
};

const SSimulation = styled.div`
  grid-area: simulation;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SImg = styled.span`
  width: 350px;
  height: 350px;
  img {
    object-fit: contain;
    display: flex;
    width: 100%;
    height: 100%;
    &:first-of-type {
      &[data-sim-color="color01"] {
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(356deg)
          brightness(100%) contrast(96%);
      }
      &[data-sim-color="color02"] {
        filter: invert(82%) sepia(8%) saturate(875%) hue-rotate(314deg)
          brightness(99%) contrast(108%);
      }
      &[data-sim-color="color03"] {
        filter: invert(54%) sepia(64%) saturate(3829%) hue-rotate(329deg)
          brightness(101%) contrast(105%);
      }
      &[data-sim-color="color04"] {
        filter: invert(69%) sepia(100%) saturate(593%) hue-rotate(328deg)
          brightness(106%) contrast(102%);
      }
      &[data-sim-color="color05"] {
        filter: invert(95%) sepia(41%) saturate(997%) hue-rotate(329deg)
          brightness(104%) contrast(115%);
      }
      &[data-sim-color="color06"] {
        filter: invert(78%) sepia(69%) saturate(499%) hue-rotate(75deg)
          brightness(99%) contrast(104%);
      }
      &[data-sim-color="color07"] {
        filter: invert(68%) sepia(77%) saturate(1602%) hue-rotate(151deg)
          brightness(101%) contrast(104%);
      }
      &[data-sim-color="color08"] {
        filter: invert(28%) sepia(0%) saturate(0%) hue-rotate(212deg)
          brightness(91%) contrast(83%);
      }
    }
    &:last-of-type {
      mix-blend-mode: multiply;
      transform: translateY(-100%);
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 500px;
    height: 500px;
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
    width: 250px;
    height: 250px;
  }
`;
