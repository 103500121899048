import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { MenuToggleContext } from "../provider/MenuToggleProvider";
// utils
import { Colors } from "../../utils/Colors";

export const Hamburger = () => {
  const { isOpen, setIsOpen } = useContext(MenuToggleContext);
  const onClickMenu = () => setIsOpen(!isOpen);
  return (
    <SHamburger onClick={onClickMenu} data-menu-state={isOpen}>
      <div></div>
      <div></div>
      <div></div>
    </SHamburger>
  );
};

const SHamburger = styled.button`
  width: 40px;
  height: 40px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  div {
    transition: all 0.2s;
    width: 28px;
    height: 1px;
  }
  &[data-menu-state="false"] {
    div {
      background-color: ${Colors.dark};
      &:nth-of-type(1) {
        margin-bottom: -1px;
        transform: translateY(-6px);
      }
      &:nth-of-type(3) {
        margin-top: -1px;
        transform: translateY(6px);
      }
    }
  }
  &[data-menu-state="true"] {
    div {
      background-color: ${Colors.light};
      &:nth-of-type(1) {
        margin-bottom: -1px;
        transform: translateY(0) rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        margin-top: -1px;
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
`;
