import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { DetailToggleContext } from "../provider/DetailToggleProvider";
import { NavItemSelect } from "./NavItemSelect";
import { NavColorSelect } from "./NavColorSelect";
import { NavPatternSelect } from "./NavPatternSelect";
import { NavSaveSelect } from "./NavSaveSelect";
// utils
import { Colors } from "../../utils/Colors";

export const Nav = () => {
  const { isOpen, setIsOpen } = useContext(DetailToggleContext);
  const onClickDetail = () => setIsOpen("close");
  return (
    <SNav>
      <SButton
        onClick={onClickDetail}
        data-detail-state={isOpen}
        className="material-icons-outlined"
      >
        expand_more
      </SButton>
      <NavItemSelect />
      <NavColorSelect />
      <NavPatternSelect />
      <NavSaveSelect />
    </SNav>
  );
};

const SNav = styled.nav`
  position: relative;
  grid-area: nav;
  background-color: ${Colors.white};
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
  gap: 8px;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 1rem;
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(calc(100% / 4 - 8px), 100%), 1fr)
    );
  }
  /* ---------- */
  /* SP */
  @media screen and (max-width: 599px) {
  }
`;

const SButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  position: absolute;
  top: -32px;
  right: 2rem;
  border: none;
  background-color: ${Colors.white};
  border-radius: 16px 16px 0 0;
  cursor: pointer;
  line-height: 1;
  &[data-detail-state="close"] {
    display: none;
  }
`;
